(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/corporate-info/views/corporate-info.js') >= 0) return;  svs.modules.push('/components/lb-ui/corporate-info/views/corporate-info.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.corporate_info=_svs.lb_ui.corporate_info||{};
_svs.lb_ui.corporate_info.templates=_svs.lb_ui.corporate_info.templates||{};
svs.lb_ui.corporate_info.templates.corporate_info = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"corporate-info-legal-info\">Spel från Svenska Spel Sport &amp; Casino AB</div>\n<div class=\"clearfix visible-lg-up\"></div>";
},"useData":true});
Handlebars.partials['components-lb_ui-corporate_info-corporate_info'] = svs.lb_ui.corporate_info.templates.corporate_info;
})(svs, Handlebars);


 })(window);