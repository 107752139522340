(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/corporate-info/assets/javascripts/corporate-info.js') >= 0) return;  svs.modules.push('/components/lb-ui/corporate-info/assets/javascripts/corporate-info.js');

'use strict';

svs.lbUi = svs.lbUi || {};
class CorporateInfo {
  constructor() {
    const $body = $('body'),
      corporateInformationPreventAnimationClass = 'corporate-info-animation-disabled';
    if (svs.core.detect.breakpoint.greaterThan('md')) {
      $body.addClass(corporateInformationPreventAnimationClass);
    } else {
      svs.core.detect.breakpoint.on('change', () => {
        if (svs.core.detect.breakpoint.greaterThan('md')) {
          $body.addClass(corporateInformationPreventAnimationClass);
        }
      });
    }
    if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      $('.js-corporate-info-pause24-link').on('click', e => this.onPause24Click(e));
    }
  }
  onPause24Click(e) {
    e.preventDefault();
    if (svs.accountservices && svs.accountservices.pause24) {
      svs.accountservices.pause24.show();
    } else {
      const loader = new svs.components.Loader();
      loader.show(300, 'inverted');
      svs.core.module.load('/cl/accountservices/pause24', null, error => {
        loader.hide();
        if (error && error.statusText === 'error') {
          svs.core.module.load('/cl/components/message-box', null, () => {
            svs.components.dialog.api.add(new svs.components.dialog.Confirm({
              branding: svs.components.dialog.branding.SPORT,
              title: 'Tekniskt fel',
              icon: 'help-2',
              area: svs.components.dialog.area.POPUP,
              type: svs.components.dialog.type.CONFIRM,
              message: [{
                type: svs.components.dialog.message.TEXT,
                text: 'För tillfället upplever vi tekniska problem. Vänligen försök igen senare.'
              }],
              actions: [{
                title: 'Okej'
              }]
            }));
          });
        } else {
          svs.accountservices.pause24.show();
        }
      }, false);
    }
  }
}
svs.lbUi.corporate_info = new CorporateInfo();

 })(window);